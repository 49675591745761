
canvas {
  width: 100%;
  max-height: 1080px;
  object-fit: contain;
}

.posts .img-post {
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-height: 400px;
  border-radius: 20px;
  border: 1px solid #d1d3d5;
}
.posts .card-post {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #C3C5D4;
}
.posts .generate {
width:100px  !important;
right:0px !important
}

.posts .padd-20 {
  padding: 20px;
}